<template>
  <Main>
    <div class="df jcsb aic">
      <sdPageHeader :title="i18n.t(`menuItems.${$route.params.type}`, 0)" />
      <div class="total-stat">
        <div class="total-stat__amount" v-if="total.amount">
          {{ i18n.t('accounts.invest.total') }} {{ i18n.n(+total?.amount, 'decimal') }} USDT
        </div>
        <div class="total-stat__profit" v-if="total.profit_all">
          <div class="total-stat__profit-item">
            {{ i18n.t('accounts.invest.profit_all') }} {{ total?.profit_all }} %
          </div>
        </div>
      </div>
    </div>
    <EmptyInvests :isLoading="isLoading" v-if="!dataSource.length && this.$route.params.type === 'invest'" />
    <div v-else-if="dataSource">
      <a-row :gutter="20" v-if="total.profit_usdt">
        <a-col :lg="8" :md="12" :xs="24">
          <sdCards class="start__header investor-card">
            <sdHeading as="h5" style="margin-right: auto">{{ i18n.t('partners.stats.profit') }}</sdHeading>
            <div class="stat-block">
              <div class="stat-block__inner">
                <p class="stat-block__title">{{ i18n.t('partners.stats.day') }}:</p>
                <p class="stat-block__value">{{ total.profit_usdt.day }} USDT</p>
              </div>
              <div class="stat-block__inner">
                <p class="stat-block__title">{{ i18n.t('partners.stats.week') }}:</p>
                <p class="stat-block__value">{{ total.profit_usdt.week }} USDT</p>
              </div>
              <div class="stat-block__inner">
                <p class="stat-block__title">{{ i18n.t('partners.stats.month') }}:</p>
                <p class="stat-block__value">{{ total.profit_usdt.month }} USDT</p>
              </div>
              <div class="stat-block__inner">
                <p class="stat-block__title">{{ i18n.t('partners.stats.all') }}:</p>
                <p class="stat-block__value">{{ total.profit_usdt.all }} USDT</p>
              </div>
            </div>
          </sdCards>
        </a-col>
      </a-row>
      <a-row :gutter="15" v-if="dataSource.length">
        <template v-for="item of dataSource" :key="item.id">
          <a-col class="w-100" :lg="8" :md="12">
            <PublicAccountBills
              :BillAccount="item.attributes"
              :AccountId="+item.id"
              :accountType="pageType"
              v-model:publishModal="publishModal"
              v-model:investModal="investModal"
              v-model:closeModal="closeModal"
            />
          </a-col>
        </template>
      </a-row>

      <PublishModal v-model:data="publishModal" />
      <InvestPublicModal v-model:data="investModal" />
      <CloseInvestModal v-model:data="closeModal" />
    </div>
    <div class="loading-data" v-if="isLoading && (this.$route.params.type !== 'invest' || dataSource.length)">
      <a-spin size="large"></a-spin>
    </div>
  </Main>
</template>
<script>
import { Main } from '../styled';
import { useStore } from 'vuex';
import { computed, ref, defineAsyncComponent, watchEffect } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { publicTypes } from '@/config/helpers/JSONdata';

const PublicAccountBills = defineAsyncComponent(() => import('@/components/elements/PublicAccountBills.vue'));
const ModalCash = defineAsyncComponent(() => import('@/components/modals/ModalCash'));
const PublishModal = defineAsyncComponent(() => import('@/components/modals/PublishModal'));
const InvestPublicModal = defineAsyncComponent(() => import('@/components/modals/InvestPublicModal'));
const CloseInvestModal = defineAsyncComponent(() => import('@/components/modals/CloseInvestModal'));
const EmptyInvests = defineAsyncComponent(() => import('@/components/elements/EmptyInvests'));

const ViewPage = {
  name: 'ViewPage',
  components: {
    Main,
    ModalCash,
    PublishModal,
    PublicAccountBills,
    InvestPublicModal,
    CloseInvestModal,
    EmptyInvests,
  },
  setup() {
    const route = useRoute();
    const { state, dispatch } = useStore();
    const i18n = useI18n();

    const title = ref('');
    const publishModal = ref({ data: null, visible: false });
    const investModal = ref({ data: null, visible: false });
    const closeModal = ref({ data: null, visible: false });
    const page = ref(1);

    const currentType = ref('');

    const pageType = computed(() => publicTypes[route.params.type]);
    const isLoading = computed(() => state.publicAccounts.loading);
    const dataSource = computed(() => state.publicAccounts[route.params.type].data);
    const pageMeta = computed(() => state.publicAccounts[route.params.type].meta);

    const total = computed(() => state.publicAccounts.invest.total);

    const onChangePage = (page, restart) => {
      dispatch('getPublicAccounts', { type: pageType.value, page, per_page: 18, restart });
    };

    const scrollPage = (event) => {
      if (
        event.target.scrollingElement.scrollTop + event.target.scrollingElement.clientHeight + 500 >
          event.target.scrollingElement.scrollHeight &&
        !isLoading.value &&
        dataSource.value.length &&
        page.value !== pageMeta.value.pages
      ) {
        page.value++;
        onChangePage(page.value);
      }
      if (page.value === pageMeta.value.pages) {
        window.removeEventListener('scroll', scrollPage);
      }
    };

    watchEffect(() => {
      if (route.params.type !== currentType.value && route.path.includes('trading/')) {
        currentType.value = route.params.type;
        onChangePage(1, true);
        window.addEventListener('scroll', scrollPage);
      } else {
        window.removeEventListener('scroll', scrollPage);
      }
    });

    return {
      i18n,
      title,
      investModal,
      isLoading,
      dataSource,
      publishModal,
      pageType,
      closeModal,
      total,
      onChangePage,
    };
  },
};

export default ViewPage;
</script>

<style scoped lang="scss">
.loading-data {
  height: 50px !important;
}
.total-stat {
  padding: 10px 20px 0 0;
  .total-stat__amount {
    font-size: 16px;
    font-weight: 600;
  }
  .total-stat__profit {
    display: flex;

    .total-stat__profit-item {
      font-size: 16px;
    }
  }
}
.investor-card {
  .stat-block {
    display: flex;
    justify-content: flex-start; //space-around;
    gap: 20px;
    width: 100%;
    margin-top: 20px;

    &__inner {
    }
    &__title {
      font-size: 14px;
      margin: 0;
    }
    &__value {
      font-size: 16px;
      margin: 0;
      font-weight: 600;
      //text-align: center;
    }
  }
}
</style>
